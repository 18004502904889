<template>
    <div>
        <v-simple-table v-if="$root.getWindowWidth() > 900">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center">
                            รูป
                        </th>
                        <th class="text-center" style="width: 20%">
                            สินค้า
                        </th>
                        <th class="text-center" style="min-width: 150px">
                            ราคา/หน่วย
                        </th>
                        <th class="text-center" style="min-width: 150px">
                            ส่วนลด/หน่วย
                        </th>
                        <th class="text-center">
                            จำนวน
                        </th>
                        <th class="text-center">
                            ราคารวม
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>                  
                    <tr v-for="(item, index) in $attrs.value.orderItems" :key="item.id + '_' + index">
                      <td>
                        <light-box v-if="!!item.imageURL"
                        :src="item.imageURL"
                        max-width="100"
                        max-height="100"
                        ></light-box>
                        <product-image v-else :image="null" />
                      </td>
                      <td style="border-bottom: none">
                          {{ item.name }}
                          <v-textarea
                            v-if="item.showNote"
                            v-model="item.note"
                            outlined
                            :disabled="disabled"
                          ></v-textarea>
                          <div 
                            class="text--red text-caption" 
                            style="cursor: pointer"
                            v-else
                            @click="item.showNote = true"
                          ><v-icon>{{ icons.mdiPlaylistEdit }}</v-icon> หมายเหตุ</div>                          
                      </td>
                      <td style="border-bottom: none">
                        <v-text-field
                            class="input-align-right my-auto"
                            type="number"
                            suffix="฿"
                            outlined
                            dense
                            v-model.number="item.price"
                            :rules="[validators.required, validators.minValueValidator(item.price, 0)]"
                            :disabled="disabled"
                        ></v-text-field>
                      </td>
                      <td style="border-bottom: none">
                        <toogle-text-field
                            class="input-align-right my-auto"
                            type="number"
                            outlined
                            dense
                            v-model.number="item.discount"
                            v-bind:toogleValue.sync="item.discount_type"
                            :rules="getDiscountRules(item.discount, item.discount_type)"
                            :disabled="disabled"
                        ></toogle-text-field>                            
                      </td>
                      <td style="border-bottom: none">
                        <v-text-field
                            class="input-align-right my-auto"
                            type="number"                            
                            outlined
                            dense
                            v-model.number="item.quantity"
                            :rules="[validators.required, validators.minValueValidator(item.quantity, 1), validators.integerValidator]"
                            :disabled="disabled"
                        ></v-text-field>
                      </td>
                      <td class="pb-6 text-right" style="border-bottom: none">{{ item.priceSum }}</td>
                      <td class="pb-6" style="border-bottom: none">
                        <v-btn icon @click="deleteItem(index)" v-if="!disabled">
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" rowspan="5" style="border-bottom: none">
                      </td>
                      <td colspan="2" class="text-right" style="border-bottom: none">
                        ราคาก่อนส่วนลด
                      </td>
                      <td colspan="2" class="text-right" style="border-bottom: none">{{ $attrs.value.priceBeforeDiscount | formatNumber }}</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-right" style="border-bottom: none">
                        ส่วนลดทั้งบิล
                      </td>
                      <td colspan="2" style="border-bottom: none">
                        <toogle-text-field
                            class="input-align-right mt-6"
                            type="number"
                            outlined
                            dense
                            v-model.number="$attrs.value.discount"
                            v-bind:toogleValue.sync="$attrs.value.discount_type"
                            :rules="getDiscountRules($attrs.value.discount, $attrs.value.discount_type)"
                            :disabled="disabled"
                        ></toogle-text-field>   
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-right font-weight-bold">
                        รวมทั้งสิ้น
                      </td>
                      <td colspan="2" class="text-right font-weight-bold">
                        {{ $attrs.value.totalPrice | formatNumber }}
                      </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="product-input-mobile" v-else>
          <div class="title text-center">รายการสินค้า</div>
          <div class="product-item" v-for="(item, index) in $attrs.value.orderItems" :key="item.id + '_' + index">                           
            <div class="product-item-tr">
              <div class="product-item-image">
                <light-box v-if="!!item.imageURL"
                :src="item.imageURL"
                max-width="100"
                max-height="100"
                ></light-box>
                <product-image v-else :image="null" />
              </div>
              <div class="product-item-name">
                {{ item.name }}
                <v-textarea
                  v-if="item.showNote"
                  v-model="item.note"
                  outlined
                  :disabled="disabled"
                ></v-textarea>
                <div 
                  class="text--red text-caption" 
                  style="cursor: pointer"
                  v-else
                  @click="item.showNote = true"
                ><v-icon>{{ icons.mdiPlaylistEdit }}</v-icon> หมายเหตุ</div>
              </div>
              <div class="pb-6" style="border-bottom: none">
                <v-btn icon @click="deleteItem(index)" v-if="!disabled">
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="product-item-tr">
              <div class="product-item-header py-2">ราคา</div>
              <div class="product-item-content">
                <v-text-field
                    class="input-align-right my-auto"
                    type="number"
                    suffix="฿"
                    outlined
                    dense
                    v-model.number="item.price"
                    :rules="[validators.required, validators.minValueValidator(item.price, 0)]"
                    :disabled="disabled"
                ></v-text-field>
              </div>
            </div>
            <div class="product-item-tr">
              <div class="product-item-header py-2">ส่วนลด</div>
              <div class="product-item-content">
                <toogle-text-field
                  class="input-align-right my-auto"
                  type="number"
                  outlined
                  dense
                  v-model.number="item.discount"
                  v-bind:toogleValue.sync="item.discount_type"
                  :rules="getDiscountRules(item.discount, item.discount_type)"
                  :disabled="disabled"
                ></toogle-text-field>
              </div>
            </div>
            <div class="product-item-tr">
              <div class="product-item-header py-2">จำนวน</div>
              <div class="product-item-content">
                <v-text-field
                  class="input-align-right my-auto"
                  type="number"                            
                  outlined
                  dense
                  v-model.number="item.quantity"
                  :rules="[validators.required, validators.minValueValidator(item.quantity, 1), validators.integerValidator]"
                  :disabled="disabled"
                ></v-text-field>
              </div>
            </div>
            <div class="product-item-tr">
              <div class="product-item-header">รวม</div>
              <div class="product-item-content text-right">{{ item.priceSum }}</div>
            </div>            
            
            <v-divider class="py-2"></v-divider>
          </div>
          <div>
            <div>
              <div>ราคาก่อนส่วนลด</div>
              <div class="text-right">{{ $attrs.value.priceBeforeDiscount | formatNumber }}</div>
            </div>
            <div>
              <div>ส่วนลดทั้งบิล</div>
              <div>
                <toogle-text-field
                    class="input-align-right mt-6"
                    type="number"
                    outlined
                    dense
                    v-model.number="$attrs.value.discount"
                    v-bind:toogleValue.sync="$attrs.value.discount_type"
                    :rules="getDiscountRules($attrs.value.discount, $attrs.value.discount_type)"
                    :disabled="disabled"
                ></toogle-text-field>
              </div>
            </div>
            <div>
              <div>รวมทั้งสิ้น</div>
              <div class="text-right">{{ $attrs.value.totalPrice | formatNumber }}</div>
            </div>
          </div>
        </div>
    </div>    
</template>
<script>
import ToogleTextField from '@/components/ToogleTextField.vue'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import { mdiBarcodeScan, mdiDeleteOutline, mdiPlaylistEdit } from '@mdi/js'
import LightBox from '../LightBox.vue'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scannedData: null,
      icons: {
        mdiBarcodeScan,
        mdiPlaylistEdit,
        mdiDeleteOutline,
      },
      scannedCache: [],
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  methods: {
    deleteItem(index) {
      const order = this.$attrs.value
      const newOrderItems = [...order.orderItems]
      newOrderItems.splice(index, 1)
      order.orderItems = newOrderItems

      this.$emit('input', order)
    },
    filterItem(item, queryText, itemText) {
      const tokens = queryText.split(' ')

      const searchText = tokens == queryText ? queryText : tokens[0]

      return (
        itemText.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
        (item.sku && item.sku.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1) ||
        (item.barCode && item.barCode.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
      )
    },
    getDiscountRules(discount_value, discount_type) {
      return discount_type == 'percent'
        ? [minValueValidator(discount_value, 0), maxValueValidator(discount_value, 100)]
        : [minValueValidator(discount_value, 0)]
    },
  },
  components: {
    ToogleTextField,
    LightBox,
  },
}
</script>
<style scoped>
.product-input-mobile {
  display: flex;
  flex-direction: column;
}

.product-input-mobile > .title {
  background-color: gray;
  color: white;
}

.product-input-mobile > .product-item {
  display: flex;
  flex-direction: column;
}

.product-input-mobile > .product-item > .product-item-tr {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-image {
  max-width: 100px;
  width: 100px;
  height: 100px;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-header {
  width: 100px;
  min-width: 100px;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-content {
  width: 100%;
}
</style>
<template>
    <div>
        <h2>ขาย > เครื่อง POS > รายการขาย > ดูรายการขาย {{ order.orderNumber }}</h2>
        <v-row class="mt-4">
          <v-col>
            <v-card>
              <v-form v-model="isFormValid">
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <p-o-s-order-viewer
                      v-model="order"
                      :disabled="true"
                      ></p-o-s-order-viewer>                      
                    </v-col>              
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>              
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined                        
                        hide-details="auto"
                        v-model="order.customerName"
                        label="ชื่อ - นามสกุล"                    
                        no-data-text="ไม่พบข้อมูล"                        
                        dense
                        :disabled="true"   
                      >
                      </v-text-field>
                      <!--
                      <delay-combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.name"
                        label="ชื่อ - นามสกุล"                    
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/pos/customerListByName'"
                        dense
                        ref="customerNameCombobox"                    
                        item-text="name"
                        item-value="name"
                        :filter="filterCustomerByName"
                        :lazySearch="true"                        
                        :disabled="true"                        
                      >
                      </delay-combobox>
                    -->
                    </v-col>  
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined                        
                        hide-details="auto"
                        v-model="order.customerTel"
                        label="เบอร์"                                            
                        dense
                        :disabled="true"   
                      >
                      </v-text-field>
                      <!--
                      <delay-combobox
                        outlined
                        hide-selected
                        hide-details="auto"
                        v-model="order.tel"
                        label="เบอร์"                    
                        no-data-text="ไม่พบข้อมูล"
                        :url="'/pos/customerListByTel'"
                        dense
                        ref="customerTelCombobox"                    
                        item-text="tel"
                        item-value="tel"
                        :filter="filterCustomerByTel"
                        :lazySearch="true"
                        @change="selectCustomer"
                        :disabled="true"
                      >
                      </delay-combobox>
                      -->
                    </v-col>                    
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        outlined
                        v-model="order.note"
                        label="หมายเหตุ"                        
                        :disabled="true"
                      ></v-textarea>
                    </v-col>              
                  </v-row>
                </v-container>
              </v-form>

              <v-card-actions class="justify-end">
                <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
                >
                กลับ
                </v-btn>          
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col           
          cols="12"
          sm="3"
          >
            <v-card>
              <v-card-title>สถานะ: {{ order.status | orderStatusToText }}</v-card-title>              
            </v-card>
            <v-card class="mt-2 pb-4" v-if="order.isPaid">
              <v-card-title>การชำระ</v-card-title>
              <div class="px-5">
                {{ order.payment }}
              </div>
            </v-card>
          </v-col>
        </v-row>
    </div>
</template>
<script>
import { POSOrder } from '@/classes/pos/POSOrder'
import DelayCombobox from '@/components/DelayCombobox.vue'
import LightBox from '@/components/LightBox.vue'
import POSOrderViewer from '@/components/order/POSOrderViewer.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import { isString } from '@vueuse/shared'
export default {
  data() {
    return {
      orderNumber: null,
      isFormValid: false,
      sendingData: false,
      order: null,
      salechannel: null,
      warehouse: null,
      currentStatus: null,
      changeStatusDialog: {
        isShow: false,
        title: '',
        body: '',
      },
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  async created() {
    const posorder_id = this.$route.params.posorder_id
    const device_id = this.$route.params.device_id

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/pos/' + device_id + '/order/' + posorder_id)

      this.order = new POSOrder(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    filterCustomerByName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerByTel(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.tel && item.tel.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    filterCustomerBySNSName(item, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        (item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      )
    },
    selectCustomer(payload) {
      if (!payload || isString(payload)) return

      const { name, email, address, city, county, postcode, province, tel } = payload

      if (!name || !tel) return

      this.order.tel = tel
      this.order.name = name
      this.order.address = address
      this.order.email = email
      this.order.county = county
      this.order.city = city
      this.order.postcode = postcode
      this.order.province = province
    },
  },
  components: {
    POSOrderViewer,
    DelayCombobox,
    LightBox,
  },
}
</script>,